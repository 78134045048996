<template>
  <div class="col-4 d-flex justify-center">
    <div class="module">
      <div
        class="module__icon elevation-5 rounded-xl"
        @click="redirectToModule"
        v-ripple
      >
        <img :src="imageUrl" :alt="description" />
      </div>
      <div class="module__description mt-2 text-center">{{ description }}</div>
    </div>
  </div>
</template>

<style scoped>
.module {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.module__icon {
  width: 100px;
  min-width: 100px;
  height: auto;
}

.module__icon img {
  display: inline-flex;
  width: inherit;
  height: inherit;
  padding: 15px;
}

.module__description {
  width: 100px;
}
</style>

<script>
import { BASE_IMAGES_URL } from "@/constants/ServicesConstants";

export default {
  name: "ItemListAppModules",
  props: ["item", "referenceItem", "slug"],
  data() {
    return {
      defaultImage: require(`@/assets/images/app_modules/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/prog_general_icon.svg`),
    };
  },
  methods: {
    redirectToModule() {
      if (!this.referenceItem?.link) return;

      this.$router
        .push({
          name: this.referenceItem.link,
          params: { slug: this.slug },
        })
        .catch(() => {});
    },
  },
  computed: {
    imageUrl() {
      if (this.item?.mainImageUrl) {
        return `${BASE_IMAGES_URL}${this.item.mainImageUrl}`;
      }
      if (!this.referenceItem?.defaultImage) return this.defaultImage;
      return require(`@/assets/images/app_modules/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/${this.referenceItem.defaultImage}.svg`);
    },
    description() {
      return this.item?.externalName ?? this.item?.moduleDescription ?? null;
    },
  },
};
</script>
