export default {
  methods: {
    showSuccess(message, time = 2000) {
      this.$snotify.success(message || "Operación exitosa", "", {
        timeout: time,
        showProgressBar: false,
      });
    },
    showError(message, time = 2000) {
      this.$snotify.error(
        message || "Ha ocurrido un error en el servidor",
        "",
        {
          timeout: time,
          showProgressBar: false,
        }
      );
    },
    showWarning(message, time = 2000) {
      this.$snotify.warning(message || "Revisar", "", {
        timeout: time,
        showProgressBar: false,
      });
    },
    invalidFields() {
      this.showError("Algunos campos son inválidos");
    },
    errorHandler(statusCode) {
      if (statusCode == 500) this.showError();

      if (statusCode == 401) {
        this.showError("No esta autorizado para acceder a esta sección");
        this.$route.push({ name: "fair" });
      }

      if (statusCode == 404) {
        this.showError("No se encontro el recurso");
        this.$router.go(-1);
      }
    },
  },
};
