import eventHub from "@/eventhub";

export default {
  methods: {
    showLoader() {
      eventHub.$emit("showLoader");
    },
    hideLoader() {
      eventHub.$emit("hideLoader");
    },
  },
};
