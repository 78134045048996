<template>
  <div>
    <div class="pa-3">
      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3 d-flex flex-column">
          <h2 class="text-uppercase" v-text="fair.name"></h2>
          <p class="my-0">
            Gestione la información del evento de manera práctica y en un solo
            lugar.
          </p>
        </div>
      </div>
      <div class="d-flex justify-center mb-2" v-if="fair.app_modules">
        <div class="col-12 col-md-5 col-lg-3">
          <div class="row justify-center">
            <item-list-app-modules
              v-for="(item, index) in fair.app_modules"
              :key="index"
              :item="item"
              :slug="fair.slug"
              :referenceItem="getReferenceItem(item.modules)"
            ></item-list-app-modules>
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons pa-3 py-2">
      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3 px-2">
          <div class="d-flex flex-column">
            <social-media></social-media>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-buttons {
  position: fixed;
  bottom: 56px;
  width: 100%;
  background: white;
}
</style>

<script>
import loaderMixin from "@/mixins/loaderMixin";
import ItemListAppModules from "./components/ItemListAppModules.vue";
import SocialMedia from "@/components/social-media/SocialMedia.vue";

export default {
  name: "AppModules",
  components: {
    ItemListAppModules,
    SocialMedia,
  },
  data() {
    return {
      modules: {
        INF: {
          link: "general-information",
          defaultImage: "info_general_icon",
        },
        PRGEN: {
          link: "general-programming",
          defaultImage: "prog_general_icon",
        },
        EVA: {
          link: "academic-event",
          defaultImage: "evento_academico_icon",
        },
        EXCLE: {
          link: "visitors",
          defaultImage: "exclusivo_expositores_icon",
        },
        INT: {
          link: "interest-places",
          defaultImage: "sitios_interes_icon",
        },
        PLF: {
          link: "plans-fair",
          defaultImage: "planos_feria_icon",
        },
        TECH: {
          link: "conferences",
          defaultImage: "charlas_icon",
        },
        REST: {
          link: "restaurants",
          defaultImage: "restaurantes_icon",
        },
        EXP: {
          link: "expositors",
          defaultImage: "expositores_icon",
        },
      },
    };
  },
  mixins: [loaderMixin],
  methods: {
    getReferenceItem(module) {
      return this.modules[module];
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    resource(route) {
      return require(route);
    },
  },
};
</script>
