<template>
  <div v-if="socialMedia.length">
    <v-menu top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
          large
          class="text-transform-none"
        >
          Síguenos en redes sociales
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title class="d-flex justify-space-around py-3">
            <div v-for="(item, index) in socialMedia" :key="index" class="px-3">
              <v-img
                max-width="35"
                max-height="35"
                :src="getUrlImage(item.socialMediaIconUrl)"
                @click="redirectToLink(item.url)"
              >
              </v-img>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>

<script>
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { BASE_IMAGES_URL } from "@/constants/ServicesConstants";

export default {
  name: "SocialMedia",
  mixins: [loaderMixin, notificationMixin],
  data() {
    return {
      socialMedia: [],
    };
  },
  methods: {
    getData() {
      this.$axiosApiMobile
        .get(`/social-media?fairId=${this.fair.id}`)
        .then((res) => {
          this.socialMedia = res.data;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    redirectToLink(link) {
      if (link) {
        let url = link.includes("http") ? link : "http://" + link;
        window.open(url, "_blank");
      }
    },
    getUrlImage(path) {
      return BASE_IMAGES_URL + path;
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
  },
  mounted() {
    this.showLoader();
    this.getData();
  },
};
</script>
